import { HideAvatar, UnhideAvatar, DeactivateUser, ReactivateUser } from "./userViewOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

export async function hideAvatar(userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Missing User Id."],
    [BE.NOT_EXIST, "User does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error hiding avatar."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: HideAvatar,
        variables: {
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unhideAvatar(userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Missing User Id."],
    [BE.NOT_EXIST, "User does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error unhiding avatar."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnhideAvatar,
        variables: {
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function deactivateUser(userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Missing User Id."],
    [BE.NOT_EXIST, "User does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error deactivating user."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DeactivateUser,
        variables: {
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reactivateUser(userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Missing User Id."],
    [BE.NOT_EXIST, "User does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error reactivating user."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReactivateUser,
        variables: {
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
