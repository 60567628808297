import { ResolveComplaintProfile, ResolveComplaintAvatar, ResolveComplaintPost } from "./userComplaintsOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

export async function resolveComplaintProfile(complaintProfileIds, comment) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Complaint does not exist or has already been resolved."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error resolving the complaint."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ResolveComplaintProfile,
        variables: {
          complaintProfileIds,
          comment
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function resolveComplaintAvatar(complaintAvatarIds, comment) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Complaint does not exist or has already been resolved."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error resolving the complaint."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ResolveComplaintAvatar,
        variables: {
          complaintAvatarIds,
          comment
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function resolveComplaintPost(complaintPostIds, comment) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Complaint does not exist or has already been resolved."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error resolving the complaint."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ResolveComplaintPost,
        variables: {
          complaintPostIds,
          comment
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
